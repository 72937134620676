import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import "./styles/Global.css";

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   // Redirect to Login on 403
//   function (error) {
//     if (error.response.status === 403) {
//       window.location.replace("/login");
//       return Promise.reject(error);
//     }
//     return Promise.reject(error);
//   }
// );

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
