import React, { useState, useEffect } from "react";
import { logoutApi, logoutReset } from "../../actions/logoutAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { hasPermission, isLogin } from "../../utils/helper";
import {
  ButtonGroup,
  HamburgerButton,
  Header,
  Logo,
  LogoWrapper,
  MobileMenu,
  Nav,
  NavLinksWrapper,
  PostJobButton,
  SignInButton,
  StyledNavLink,
} from "../../styles/GlobalStyle";
import { UserOutlined, LogoutOutlined, BookOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, notification } from "antd";
import { connect } from "react-redux";

const Navigation = (props) => {
  const {
    logout,
    logoutState,
    logoutReset,
    userIcon = "male",
    setUserIcon,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    setUserIcon(localStorage.getItem("gender"));
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const links = [
    {
      text: "Dashboard",
      path: "/dashboard",
      img: "dashboard",
      permission: { module: "dashboard" },
    },
    {
      text: "Explore",
      img: "explore",
      path: "/explore",
    },
    {
      text: "Manage",
      img: "manage",
      path: "/manage",
      permission: { module: "manage" },
    },
    {
      text: "Inventory",
      img: "inventory",
      path: "/inventory",
      permission: { module: "jobs-inventory" },
    },
    {
      text: "Employer",
      img: "employers",
      path: "/employers",
      permission: { module: "employers" },
    },
    {
      text: "Reseller",
      img: "reseller",
      path: "/reseller",
      permission: { module: "resellers" },
    },
    {
      text: "Applications",
      img: "applications",
      path: "/applications",
      permission: {
        module: "candidate-applications",
      },
    },
    {
      text: "My Applications",
      img: "my-applications",
      path: "/my-applications",
      permission: { module: "my-applications" },
    },
    {
      text: "Candidates",
      img: "candidates",
      path: "/candidates",
      permission: { module: "candidates" },
    },
    { text: "About Us", path: "/about-us", img: "about-us" },
    { text: "Contact Us", path: "/contact-us", img: "contact-us" },
  ];

  const items = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: (
        <Link
          to={
            localStorage.getItem("role") === "candidate"
              ? "/candidate-profile"
              : "/profile"
          }>
          {localStorage.getItem("role") === "candidate"
            ? "Build Profile"
            : "Profile"}
        </Link>
      ),
    },
    ...(localStorage.getItem("role") === "candidate"
      ? [
          {
            key: "2",
            icon: <BookOutlined />,
            label: <Link to="/explore?savedOnly=true">Saved Jobs</Link>,
          },
        ]
      : []),
    {
      type: "divider",
    },
    {
      key: "3",
      icon: <LogoutOutlined />,
      label: <Button onClick={() => logout()}>Logout</Button>,
    },
  ];

  const alwaysVisibleLinks = ["About Us", "Contact Us", "Explore"];

  useEffect(() => {
    if (logoutState.apiState === "success") {
      notification.success({
        message: "Logout successful",
      });
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("username");
      localStorage.removeItem("changePassword");
      localStorage.removeItem("consentGiven");
      localStorage.removeItem("email");
      localStorage.removeItem("expiresAt");
      localStorage.removeItem("gender");
      localStorage.removeItem("permissions");
      localStorage.removeItem("role");
      logoutReset();
      navigate("/login");
    } else if (logoutState.apiState === "error") {
      notification.error({
        message: "Unable to logout",
      });
      logoutReset();
    }
  }, [logoutState.apiState]);

  return (
    <Header>
      <Nav>
        <LogoWrapper>
          <Logo onClick={() => navigate("/")}>
            <img
              role="button"
              src="./images/logoHeader.png"
              alt=""
              height={"40px"}
            />
          </Logo>
        </LogoWrapper>
        {isMobile ? (
          <HamburgerButton onClick={toggleMenu} $isOpen={isMenuOpen}>
            <span></span>
            <span></span>
            <span></span>
          </HamburgerButton>
        ) : (
          <>
            <NavLinksWrapper>
              {links.map((link, index) => {
                // Check if link should always be visible, or if user is logged in
                if (
                  alwaysVisibleLinks.includes(link.text) ||
                  (isLogin() &&
                    (!link.permission ||
                      hasPermission(
                        link.permission.module,
                        link.permission.action
                      )))
                ) {
                  // Determine if link is active based on URL or hover
                  const isUrlActive = window.location.pathname.startsWith(
                    link.path
                  );
                  const isHovered = hoveredIndex === index;
                  const isActive = isUrlActive || isHovered;

                  return (
                    <LogoWrapper
                      key={index}
                      style={{
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}>
                      <Logo
                        style={{ justifyContent: "center" }}
                        onClick={() => navigate(link.path)}>
                        <img
                          role="button"
                          src={`./images/${link.img}${
                            isActive ? "-active" : ""
                          }.png`}
                          alt=""
                          height={"30px"}
                        />
                      </Logo>

                      <StyledNavLink
                        to={link.path}
                        onClick={() => isMobile && setIsMenuOpen(false)}
                        style={{
                          color: isActive ? "#275df5" : "#9199a3",
                        }}>
                        {link.text}
                      </StyledNavLink>
                    </LogoWrapper>
                  );
                }
                return null;
              })}
            </NavLinksWrapper>
            <ButtonGroup>
              {isLogin() || location.pathname === "/login" ? (
                <></>
              ) : (
                <SignInButton onClick={() => navigate("/login")}>
                  Sign in
                </SignInButton>
              )}
              {!isLogin() && location.pathname !== "/calendar" ? (
                <PostJobButton onClick={() => navigate("/calendar")}>
                  Become an Employer
                </PostJobButton>
              ) : (
                <></>
              )}
              {isLogin() ? (
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                  arrow>
                  <Button type="Default" shape="circle">
                    <Avatar src={`/images/${userIcon || "male"}.png`} />
                  </Button>
                </Dropdown>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </>
        )}
      </Nav>
      {isMobile && (
        <MobileMenu $isOpen={isMenuOpen}>
          <NavLinksWrapper>
            {links.map((link, index) => {
              // Check if link should always be visible, or if user is logged in
              if (
                alwaysVisibleLinks.includes(link.text) ||
                (isLogin() &&
                  (!link.permission ||
                    hasPermission(
                      link.permission.module,
                      link.permission.action
                    )))
              ) {
                return (
                  <StyledNavLink
                    key={index}
                    to={link.path}
                    end
                    onClick={() => isMobile && setIsMenuOpen(false)}>
                    {link.text}
                  </StyledNavLink>
                );
              }
              return null;
            })}
          </NavLinksWrapper>
          <ButtonGroup>
            {isLogin() || location.pathname === "/login" ? (
              <></>
            ) : (
              <SignInButton onClick={() => navigate("/login")}>
                Sign in
              </SignInButton>
            )}
            {!isLogin() && location.pathname !== "/calendar" ? (
              <PostJobButton onClick={() => navigate("/calendar")}>
                Become an Employer
              </PostJobButton>
            ) : (
              <></>
            )}
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow>
              <Button type="Default" shape="circle">
                <Avatar src={`/images/${userIcon || "male"}.png`} />
              </Button>
            </Dropdown>
          </ButtonGroup>
        </MobileMenu>
      )}
    </Header>
  );
};

const mapStateToProps = (state) => ({
  logoutState: state.logout,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutApi());
  },
  logoutReset: () => {
    dispatch(logoutReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
